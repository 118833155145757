import React from "react";
import { SVGIcon } from "./../../components/Icons";
import { StyledLoader, StyledLoaderCentered } from "./styles";

const Loader = ({ centered = false }) => {
  if (centered === false) {
    return (
      <StyledLoader>
        <SVGIcon iconName="loading" />
      </StyledLoader>
    );
  } else {
    return (
      <StyledLoaderCentered>
        <StyledLoader>
          <SVGIcon iconName="loading" />
        </StyledLoader>
      </StyledLoaderCentered>
    );
  }
};

export default Loader;
