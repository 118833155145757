import React from "react";

const hamburger = () => {
  return (
    <svg
      width="20"
      height="7"
      viewBox="0 0 20 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="0.5" x2="20" y2="0.5" stroke="black" />
      <path d="M0 6.5H12" stroke="black" />
    </svg>
  );
};

export default hamburger;
