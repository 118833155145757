import React from "react";

const logo = () => {
  return (
    <svg
      style={{
        width: 26,
        height: 30,
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38.21 55"
      fill="#b6a165"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M24.31,0l13.9,34.06L25.74,40.9c.15-.92.32-1.8.34-2.29.05-1.17-.1-1.61-.1-2.44s.55-1.75.65-3.7a9.89,9.89,0,0,0-.36-3.36,1.51,1.51,0,0,0,1.06-.69,20.15,20.15,0,0,0,.84-4.19A3.6,3.6,0,0,0,28,23.11c-.46-1.08-1.8-.89-2.4-1.59-.18-.21-.3-.2-.32-1a3.36,3.36,0,0,0,.41-2,1.41,1.41,0,0,0-2.81,0,3.36,3.36,0,0,0,.41,2c0,.78-.14.77-.33,1-.6.7-1.93.51-2.39,1.59a3.57,3.57,0,0,0-.15,1.12,19.46,19.46,0,0,0,.85,4.19,1.51,1.51,0,0,0,1.06.69A10,10,0,0,0,22,32.47c.1,2,.66,2.87.66,3.7s-.15,1.27-.1,2.44c0,.74.41,2.44.55,3.73L0,55Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default logo;
