import React from "react";

const search = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.50713 0.285767C8.39085 0.285767 10.7286 2.68115 10.7286 5.636C10.7286 6.97129 10.2512 8.19233 9.46174 9.12969L15.0476 14.8537L14.0172 15.9095L8.36341 10.1155C7.54266 10.6661 6.56138 10.9862 5.50713 10.9862C2.62342 10.9862 0.285706 8.59085 0.285706 5.636C0.285706 2.68115 2.62342 0.285767 5.50713 0.285767ZM5.50713 1.03231C3.0258 1.03231 1.01428 3.09345 1.01428 5.636C1.01428 8.17854 3.0258 10.2397 5.50713 10.2397C7.98847 10.2397 9.99999 8.17854 9.99999 5.636C9.99999 3.09345 7.98847 1.03231 5.50713 1.03231Z" fill="#999999"/>
    </svg>
  );
};

export default search;
