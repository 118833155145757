
import styled from 'styled-components';

const StyledWrapper = styled.div`
    && {
        display: block;
        text-align: center;
        ${(props) => props.customstyles};
        #aframe-desktop-logo {
          /* transform: scale(0.5, 0.5); */
          /* width: 100px; */
          /* height: 115.5px; */

        }
    }
`;
export default StyledWrapper;
