import React from "react";

const closeIconGray = () => {
  return (
    <svg
      width="9.19"
      height="9.19"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L15 15" stroke="#999" strokeMiterlimit="10" />
      <path d="M15 1L1 15" stroke="#999" strokeMiterlimit="10" />
    </svg>
  );
};

export default closeIconGray;
