import React, { createElement } from "react";
import Loadable from "react-loadable";
import PropTypes from "prop-types";
import StyledWrapper from "./styles";

import logo from "./jsx/logo";
import hamburger from "./jsx/hamburger";
import closeIconGrey from "./jsx/closeIconGrey";
import search from "./jsx/search";

// Load components;
const academyIcon = Loadable({
  loader: () => import("./jsx/academyIcon"),
  loading: () => null,
});
const academyIconMuseum = Loadable({
  loader: () => import("./jsx/academyIconMuseum"),
  loading: () => null,
});
const academyLogo = Loadable({
  loader: () => import("./jsx/academyLogo"),
  loading: () => null,
});
const academyWhiteLogo = Loadable({
  loader: () => import("./jsx/academyWhiteLogo"),
  loading: () => null,
});
const aframeIcon = Loadable({
  loader: () => import("./jsx/aframeIcon"),
  loading: () => null,
});
const aframeLogo = Loadable({
  loader: () => import("./jsx/aframeLogo"),
  loading: () => null,
});
const aFrameDesktopLogo = Loadable({
  loader: () => import("./jsx/academyLogo"),
  loading: () => null,
});
const amazonIcon = Loadable({
  loader: () => import("./jsx/amazonIcon"),
  loading: () => null,
});
const appleIcon = Loadable({
  loader: () => import("./jsx/appleIcon"),
  loading: () => null,
});
const assetMask = Loadable({
  loader: () => import("./jsx/assetMask"),
  loading: () => null,
});
const assetMaskDesktop = Loadable({
  loader: () => import("./jsx/assetMaskDesktop"),
  loading: () => null,
});
const closeIcon = Loadable({
  loader: () => import("./jsx/closeIcon"),
  loading: () => null,
});
const disneyIcon = Loadable({
  loader: () => import("./jsx/disneyIcon"),
  loading: () => null,
});
const download = Loadable({
  loader: () => import("./jsx/download"),
  loading: () => null,
});
const email = Loadable({
  loader: () => import("./jsx/email"),
  loading: () => null,
});
const emailIcon = Loadable({
  loader: () => import("./jsx/emailIcon"),
  loading: () => null,
});
const facebookIcon = Loadable({
  loader: () => import("./jsx/facebookIcon"),
  loading: () => null,
});
const fbIcon = Loadable({
  loader: () => import("./jsx/fbIcon"),
  loading: () => null,
});
const fbIconInverted = Loadable({
  loader: () => import("./jsx/fbIconInverted"),
  loading: () => null,
});
const filledCircle = Loadable({
  loader: () => import("./jsx/filledCircle"),
  loading: () => null,
});
const googleIcon = Loadable({
  loader: () => import("./jsx/googleIcon"),
  loading: () => null,
});
// const hamburger = Loadable({
//   loader: () => import("./jsx/hamburger"),
//   loading: () => null,
// });
const hboGoIcon = Loadable({
  loader: () => import("./jsx/hboGoIcon"),
  loading: () => null,
});
const hboMaxIcon = Loadable({
  loader: () => import("./jsx/hboMaxIcon"),
  loading: () => null,
});
const huluIcon = Loadable({
  loader: () => import("./jsx/huluIcon"),
  loading: () => null,
});
const infoHand = Loadable({
  loader: () => import("./jsx/infoHand"),
  loading: () => null,
});
const instagramIcon = Loadable({
  loader: () => import("./jsx/instagramIcon"),
  loading: () => null,
});
const instaIcon = Loadable({
  loader: () => import("./jsx/instaIcon"),
  loading: () => null,
});
const instaIconInverted = Loadable({
  loader: () => import("./jsx/instaIconInverted"),
  loading: () => null,
});
const itunesIcon = Loadable({
  loader: () => import("./jsx/itunesIcon"),
  loading: () => null,
});
const leftIcon = Loadable({
  loader: () => import("./jsx/leftIcon"),
  loading: () => null,
});
const rightIcon = Loadable({
  loader: () => import("./jsx/rightIcon"),
  loading: () => null,
});
const lens = Loadable({
  loader: () => import("./jsx/lens"),
  loading: () => null,
});
const linkedInIcon = Loadable({
  loader: () => import("./jsx/linkedInIcon"),
  loading: () => null,
});
const linkedInIconInverted = Loadable({
  loader: () => import("./jsx/linkedInIconInverted"),
  loading: () => null,
});
const loaderIcon = Loadable({
  loader: () => import("./jsx/loaderIcon"),
  loading: () => null,
});
const loading = Loadable({
  loader: () => import("./jsx/loading"),
  loading: () => null,
});
// const logo = Loadable({
//   loader: () => import("./jsx/logo"),
//   loading: () => null,
// });
const logoWhite = Loadable({
  loader: () => import("./jsx/logoWhite"),
  loading: () => null,
});
const makeMyListIcon = Loadable({
  loader: () => import("./jsx/makeMyListIcon"),
  loading: () => null,
});
const minusIcon = Loadable({
  loader: () => import("./jsx/minusIcon"),
  loading: () => null,
});
const monoLogo = Loadable({
  loader: () => import("./jsx/monoLogo"),
  loading: () => null,
});
const museumLogo = Loadable({
  loader: () => import("./jsx/museumLogo"),
  loading: () => null,
});
const netflixIcon = Loadable({
  loader: () => import("./jsx/netflixIcon"),
  loading: () => null,
});
const playCircleIcon = Loadable({
  loader: () => import("./jsx/playCircleIcon"),
  loading: () => null,
});
const playIcon = Loadable({
  loader: () => import("./jsx/playIcon"),
  loading: () => null,
});
const plusCircle = Loadable({
  loader: () => import("./jsx/plusCircle"),
  loading: () => null,
});
const plusIcon = Loadable({
  loader: () => import("./jsx/plusIcon"),
  loading: () => null,
});
const plusSquare = Loadable({
  loader: () => import("./jsx/plusSquare"),
  loading: () => null,
});
const providerPlaceholderIcon = Loadable({
  loader: () => import("./jsx/providerPlaceholderIcon"),
  loading: () => null,
});
const quotationIcon = Loadable({
  loader: () => import("./jsx/quotationIcon"),
  loading: () => null,
});
const selectedEmpty = Loadable({
  loader: () => import("./jsx/selectedEmpty"),
  loading: () => null,
});
const selectedFilled = Loadable({
  loader: () => import("./jsx/selectedFilled"),
  loading: () => null,
});
const shopIcon = Loadable({
  loader: () => import("./jsx/shopIcon"),
  loading: () => null,
});
const showtimeIcon = Loadable({
  loader: () => import("./jsx/showtimeIcon"),
  loading: () => null,
});
const shudderIcon = Loadable({
  loader: () => import("./jsx/shudderIcon"),
  loading: () => null,
});
const sorting = Loadable({
  loader: () => import("./jsx/sorting"),
  loading: () => null,
});
const squarePlayIcon = Loadable({
  loader: () => import("./jsx/squarePlayIcon"),
  loading: () => null,
});
const starzIcon = Loadable({
  loader: () => import("./jsx/starzIcon"),
  loading: () => null,
});
const successMark = Loadable({
  loader: () => import("./jsx/successMark"),
  loading: () => null,
});
const tbsIcon = Loadable({
  loader: () => import("./jsx/tbsIcon"),
  loading: () => null,
});
const ticketIcon = Loadable({
  loader: () => import("./jsx/ticketIcon"),
  loading: () => null,
});
const times = Loadable({
  loader: () => import("./jsx/times"),
  loading: () => null,
});
const timesCircle = Loadable({
  loader: () => import("./jsx/timesCircle"),
  loading: () => null,
});
const timesWhite = Loadable({
  loader: () => import("./jsx/timesWhite"),
  loading: () => null,
});
const tntIcon = Loadable({
  loader: () => import("./jsx/tntIcon"),
  loading: () => null,
});
const twitterIcon = Loadable({
  loader: () => import("./jsx/twitterIcon"),
  loading: () => null,
});
const twitterIconInverted = Loadable({
  loader: () => import("./jsx/twitterIconInverted"),
  loading: () => null,
});
const unselected = Loadable({
  loader: () => import("./jsx/unselected"),
  loading: () => null,
});
const usaIcon = Loadable({
  loader: () => import("./jsx/usaIcon"),
  loading: () => null,
});
const vuduIcon = Loadable({
  loader: () => import("./jsx/vuduIcon"),
  loading: () => null,
});
const youtubeIcon = Loadable({
  loader: () => import("./jsx/youTubeIcon"),
  loading: () => null,
});
const aframeLogoHorizontal = Loadable({
  loader: () => import("./jsx/aframeLogoHorizontal"),
  loading: () => null,
});

const rightIconGold = Loadable({
  loader: () => import("./jsx/rightIconGold"),
  loading: () => null,
});

let iconsMap = {
  academyIcon: academyIcon,
  academyIconMuseum: academyIconMuseum,
  academyLogo: academyLogo,
  academyWhiteLogo: academyWhiteLogo,
  aframeIcon: aframeIcon,
  aframeLogo: aframeLogo,
  aFrameDesktopLogo: aFrameDesktopLogo,
  amazonIcon: amazonIcon,
  appleIcon: appleIcon,
  assetMask: assetMask,
  assetMaskDesktop: assetMaskDesktop,
  closeIcon: closeIcon,
  disneyIcon: disneyIcon,
  download: download,
  email: email,
  emailIcon: emailIcon,
  facebookIcon: facebookIcon,
  fbIcon: fbIcon,
  fbIconInverted: fbIconInverted,
  filledCircle: filledCircle,
  googleIcon: googleIcon,
  hamburger: hamburger,
  hboGoIcon: hboGoIcon,
  hboMaxIcon: hboMaxIcon,
  huluIcon: huluIcon,
  infoHand: infoHand,
  instagramIcon: instagramIcon,
  instaIcon: instaIcon,
  instaIconInverted: instaIconInverted,
  itunesIcon: itunesIcon,
  leftIcon: leftIcon,
  rightIcon: rightIcon,
  lens: lens,
  linkedInIcon: linkedInIcon,
  linkedInIconInverted: linkedInIconInverted,
  loaderIcon: loaderIcon,
  loading: loading,
  logo: logo,
  logoWhite: logoWhite,
  makeMyListIcon: makeMyListIcon,
  minusIcon: minusIcon,
  monoLogo: monoLogo,
  museumLogo: museumLogo,
  netflixIcon: netflixIcon,
  playCircleIcon: playCircleIcon,
  playIcon: playIcon,
  plusCircle: plusCircle,
  plusIcon: plusIcon,
  plusSquare: plusSquare,
  providerPlaceholderIcon: providerPlaceholderIcon,
  quotationIcon: quotationIcon,
  search: search,
  selectedEmpty: selectedEmpty,
  selectedFilled: selectedFilled,
  shopIcon: shopIcon,
  showtimeIcon: showtimeIcon,
  shudderIcon: shudderIcon,
  sorting: sorting,
  squarePlayIcon: squarePlayIcon,
  starzIcon: starzIcon,
  successMark: successMark,
  tbsIcon: tbsIcon,
  ticketIcon: ticketIcon,
  times: times,
  timesCircle: timesCircle,
  timesWhite: timesWhite,
  tntIcon: tntIcon,
  twitterIcon: twitterIcon,
  twitterIconInverted: twitterIconInverted,
  unselected: unselected,
  usaIcon: usaIcon,
  vuduIcon: vuduIcon,
  youtubeIcon: youtubeIcon,
  aframeLogoHorizontal: aframeLogoHorizontal,
  closeIconGrey: closeIconGrey,
  rightIconGold: rightIconGold,
};

// Registry of icons.
export const Icons = ({
  context = null,
  clientContext = null,
  iconName = null,
  styled = true,
  children = null,
  styles = null,
}) => {
  if (iconName !== null) {
    if (styled === true) {
      return (
        <SVGIcon
          iconName={iconName}
          styles={styles}
          styled={styled}
          withWrapper={true}
          clientContext={clientContext}
        />
      );
    } else {
      // Return plain svg with no wrapper
      return iconsMap[iconName];
    }
  } else {
    return null;
  }
};

export const SVGIcon = ({
  iconName = null,
  styles = null,
  styled = true,
  children = null,
  context = null,
  clientContext = null,
}) => {

  if (context !== null) {
    let { isAmp } = context;
    if (isAmp === true) {
      let icon = iconsMap[iconName] ? createElement(iconsMap[iconName]) : null;
      return (
        <div>
          {icon}
          {children}
        </div>
      );
    }
  }
  if (styled === true) {
    return (
      <StyledWrapper customstyles={styles}>
        {iconsMap[iconName] ? createElement(iconsMap[iconName]) : null}
        {children}
      </StyledWrapper>
    );
  }
  return iconsMap[iconName] ? createElement(iconsMap[iconName]) : null;
};

Icons.propTypes = {
  /**
   */
  context: PropTypes.object,
  /**
   */
  clientContext: PropTypes.object,
  /**
   */
  iconName: PropTypes.string.isRequired,
  /**
   */
  styled: PropTypes.bool,
  /**
   */
  children: PropTypes.string,
  /**
   */
  styles: PropTypes.string,
};
